<template>
  <div class="all"  @click="editbg">
    <div><img class="imges" src="../images/feduploginlogo.png" alt=""></div>
    <div class="about">
      <div class="title">登陆账号</div>
      <input class="width" v-model="account"  placeholder="请输入账号" /><br>
      <input class="width" placeholder="请输入密码" v-model="password" type="password" /><br>
      <input class="width" placeholder="请输入手机号" v-model="phone" /><br>
      <div style="display: flex;">
        <input class="width width2" placeholder="请输入验证码" v-model="code" /><el-button class="width width3" @click="cloginCode">{{gettime}}</el-button>
      </div>
      <!-- <slider-verify-code ref="child" v-model="isLock" @change="handlerLock"></slider-verify-code> -->
      <el-button class="loginbtn"  @click="doLogin()">登录</el-button>
    </div> 
  </div>
</template>
<script>
 //import post from '../lib/requset'
import ajax from '../lib/ajax'
import axios from 'axios'
import config from '../lib/config'
import {encrypt} from '../lib/secret'
// import sliderVerifyCode  from './slider-verify-code.vue'
export default {
  name: 'Login',
  components: {
    // 'slider-verify-code': sliderVerifyCode 
  },
  data(){
    return {
      gettime:'获取验证码',
      account:'',
      password:'',
      phone:'',
      code:'',
      form:{},
      isLock: true,
    }
  },
  methods: {
    doLogin(){
      let _this = this;
      if(this.account == ''){
           this.$message.error('请输入账号');
           return
        }
      if(this.password == ''){
           this.$message.error('请输入密码');
           return
        }
      if(this.phone == ''){
           this.$message.error('请输入手机号');
           return
        }
      if(this.code == ''){
           this.$message.error('请输入验证码');
           return
        }
     // if(_this.isLock){ 

         let account = encrypt(this.account);
         let password = encrypt(this.password);
         let phone = encrypt(this.phone);
         let code = encrypt(this.code);
        ajax.post(config.login,{account:account,password:password,phone:phone,code:code},(res)=>{
          res = JSON.parse(res);
            if(res.code == 200){
                localStorage.setItem("Authorization", res.data.api_token);
                localStorage.setItem("Iad", res.data.is_admin);
                localStorage.setItem("username",_this.account);
                _this.$router.push('/logo');
              }else{
                  _this.$message.error(res.message);
                  _this.isLock = false;
                  _this.$refs.child.init();
              }
        });

      // }else{ 
      //   _this.$message.error('请先完成滑块验证！');
      // }
    },
    editbg(){
      
    },
    cloginCode(data) {
      let _this = this;
      if(_this.gettime != '获取验证码'){
        _this.$message.error('操作太快了，歇一会儿吧！');
        return
      }
      let time = 10,i=1;
      if(data){
        if(this.phone == '' || _this.account == ''){
           this.$message.error('手机号或账号不能为空！');
        }else if(this.phone.length < 11){
           this.$message.error('请输入正确的手机号！');
        }else{
            _this.gettime = '10s';
            let setInt = setInterval(function(){
              _this.gettime = time-i + 's';
              i++;
              if(i>10){
                  _this.gettime = '获取验证码';
                  clearInterval(setInt);
              }
            },1000)
           let phone = encrypt(this.phone);
           let account = encrypt(this.account);
           axios.get(config.loginCode,{params:{phone:phone,account:account}})
        .then(function (response) {
            if(response.data.code == 200){
              console.log(response.data)
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
           
        }
      }
    },
    handlerLock(data) {
      if(data){
        if(this.account == '' || this.password == '' || this.phone == '' || this.code == ''){
           this.$message.error('请将登录信息完整填写！');
           this.isLock = false;
           this.$refs.child.init();
        }else{
           this.$message.success('验证成功');
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.imges{
    width: 286px;
    height:44px;
    padding-top: 100px;
    padding-bottom: 20px;
}
.title{
  color: #FFF;
  text-align: center;
  font-family: Alibaba PuHuiTi;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 28px;
}
.all{
  width: 100%;
  height: 100%;
  background-repeat:no-repeat;
  background-size:100% 100%;
  -moz-background-size:100% 100%;
  background-image:url('../images/login.jpg')
}

.about{
  width: 295px;
  margin: auto;
  padding-top: 20px;
    color: black;
    padding: 36px 24px 36px 24px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(32px);
}
.width{
  display: block;
  width: 260px;
  height: 52px;
  border-radius: 12px;
  border: 1.5px solid rgba(255, 255, 255, 0.32);
  background-color: rgba(255, 255, 255, 0);
  outline: none;
  color: #FFF;
  font-family: Alibaba PuHuiTi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 20px;
  padding-right: 14px;
}
.width::placeholder{
  color: rgba(255, 255, 255, 0.64);
  font-family: Alibaba PuHuiTi;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.width2{
  width: 125px;
  margin-right: 16px;
}
.width3{
  width: 119px;
  color: rgba(255, 255, 255, 0.64);
  padding: 0;
font-family: Alibaba PuHuiTi;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.loginbtn{
  width: 294px;
height: 52px;
flex-shrink: 0;
  border-radius: 12px;
background: #2495ED;
border: 0px;
color: #FFF;
font-family: Alibaba PuHuiTi;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 28px;
}
</style>